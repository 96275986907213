import React from 'react';
import { Grid, Image, Card, Responsive } from 'semantic-ui-react'
import './Exec.css'
import alex from '../img/Alex.jpg'
import rayyan from '../img/Rayyan.JPG'
import ramsha from '../img/ramsha.png'
import makenna from '../img/Makenna.jpg'
import anran from '../img/Anran.png'
import iqra from '../img/iqra.jpg'
import tien from '../img/Tien.jpg'
import henry from '../img/henry.jpg'

const lala = {
    height:"120vh"
}

export default class Exec extends React.Component {
    render() {
        return (
            <div id='exec-div'>
                <h1 id='exec-title'>EXECUTIVE BOARD</h1>
                <Responsive as={Grid} id='exec-grid' style={{lala}}>
                    <Grid.Row className='grid-row' columns={4}>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <div className="headshot" id="new-member"><a id="new-member-link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdEV5soG_fWijQtK8RBF5-23EPHdIfYOqzjZLnDAc-PQVnkoA/viewform">Click here to apply to one of our board positions!</a></div>
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>YOU!</Card.Header>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={tien} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Tien VoNguyen</Card.Header>
                                    <Card.Description className='member-name'>
                                        President
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={alex} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Alex Rose</Card.Header>
                                    <Card.Description className='member-name'>
                                    Vice President
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={henry} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Henry Fellerhoff</Card.Header>
                                    <Card.Description className='member-name'>
                                        Events Chair
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        
                    </Grid.Row>
                    <Grid.Row className='grid-row' columns={4}>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={anran} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Anran Wang</Card.Header>
                                    <Card.Description className='member-name'>
                                        Head of Recruitment
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={iqra} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Iqra Rehman</Card.Header>
                                    <Card.Description className='member-name'>
                                    CAN Representative and ACM Manager
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={rayyan} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Mohammed Rayyan Shaji</Card.Header>
                                    <Card.Description className='member-name'>
                                    Lead Website Developer
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column className='board-member'>
                            <Card className='member-card'>
                                <Image className='headshot' src={makenna} />
                                <Card.Content className='member-card'>
                                    <Card.Header className='member-name'>Makenna Walsh</Card.Header>
                                    <Card.Description className='member-name'>
                                        Treasurer
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                
                    </Responsive>

                
                 </div>)            
    }
}

                /* <Responsive maxWidth={991} as='div' style={{lala}}>
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <div className="headshot" id="new-member"><a id="new-member-link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdEV5soG_fWijQtK8RBF5-23EPHdIfYOqzjZLnDAc-PQVnkoA/viewform">Click here to apply to be our head of recruitment!</a></div>
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>YOU!</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={tien} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Tien VoNguyen</Card.Header>
                                <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={alex} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Alex Rose</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={makenna} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Makenna Walsh</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={anran} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Anran Wang</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={iqra} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Iqra Rehman</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <br />
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={rayyan} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Mohammed Rayyan Shaji</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className='grid-row'>
                        <Card className='member-card'>
                            <Image className='headshot' src={ramsha} />
                            <Card.Content className='member-card'>
                                <Card.Header className='member-name'>Ramsha Essa</Card.Header>
                            </Card.Content>
                        </Card>
                    </div>
                </Responsive> */
                
        