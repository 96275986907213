import React from 'react';

const Mobileframe = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;     
    return (
        // basic bootstrap classes. you can change with yours.
        <div className="col-sm-12">
            <div className="emdeb-responsive">
            <iframe src="https://discord.com/widget?id=740005650934792193&theme=dark" width="300" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
        </div>
    );
};

export default Mobileframe;