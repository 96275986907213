import React from 'react';
import { Grid, Image, Button, Form, TextArea, Responsive, Label } from 'semantic-ui-react'
import csAward from '../img/csaward.jpg'
import Iframe from '../components/iframe.js';
import Mobileframe from '../components/Mobileframe.js'

import slackLogo from '../img/slack-logo-edited.png'
import './Contact.css'

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: "https://discord.com/widget?id=740005650934792193&theme=dark"
        };
    }
    render() {
        return (
            <div id='contact-div'>
                <Responsive as={Grid} minWidth={992} id='contact-grid'>
                    <Grid.Row>
                        <Grid.Column id='image-column' width={8}>
                            <Image id='cs-award' src={csAward} />
                        </Grid.Column>
                        <Grid.Column id='form-column' width={8} height={10}>
                            <h1 id='contact-title'>CONTACT US</h1>
                         
                             <div id='contact-text'> <br />
                             
                             <a id="join-slack-link" href="https://discord.gg/P4vu5FQ">
                                    <Image id="slack-logo" src="https://discord.com/assets/2c21aeda16de354ba5334551a883b481.png"></Image>
                                    <span>Join our Discord!</span> 
                                    </a>
                                <br />
                                <Iframe source="https://discord.com/widget?id=740005650934792193&theme=dark" />
                                <br />
                                <p>Are you looking for a place with CS folks that like to geek out, learn some CS, make friends, and be in a judgment free zone and a helpful environment? Look no further. Just fill in the form below and we'll get back to you ASAP.</p>
                            </div> 
                           
                            <Form id="form" action="https://formspree.io/dontpanic.luc@gmail.com" method="POST" height={10}>
                                <Form.Field required>
                                    <Form.Input name="name" type="text" placeholder='Name' />
                                </Form.Field>
                                <Form.Field required>
                                    <Form.Input name="email" type="email" placeholder='Email' />
                                </Form.Field>
                                <TextArea name="message" required placeholder='Message' />
                                <Button id='submit' type='submit'>Submit</Button>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Responsive>
                <Responsive maxWidth={991} as='div' id='contact-grid'>
                    <h4 id='contact-title'>CONTACT US</h4>
                   
                    <div id='image-wrapper'>
                        <Image id='cs-award' src={csAward} />
                    </div>
                    <p id='contact-text' >
                        <a target="_blank" id="join-slack-link" href="https://discord.gg/P4vu5FQ"><Image id="slack-logo" src="https://discord.com/assets/2c21aeda16de354ba5334551a883b481.png" /><span>Join our Discord!</span></a>
                        <br />
                         <Mobileframe source="https://discord.com/widget?id=740005650934792193&theme=dark" /> 
                        <br />
                        Are you looking for a place with CS folks that like to geek out, learn some CS, make friends, and be in a judgment free zone and a helpful environment? Look no further. Just fill in the form below and we'll get back to you ASAP.
                    </p>
                    
                    <Form id="form" action="https://formspree.io/dontpanic.luc@gmail.com" method="POST">
                        <Form.Field required>
                            <Form.Input name="name" type="text" placeholder='Name' />
                        </Form.Field>
                        <Form.Field required>
                            <Form.Input name="email" type="email" placeholder='Email' />
                        </Form.Field>
                        <TextArea name="message" required placeholder='Message' />
                        <Button id='submit' type='submit'>Submit</Button>
                    </Form>
                    <br />
                </Responsive>
            </div>
        )
    }

}