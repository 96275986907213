import React from 'react';
import { List, Grid, Image, Responsive, Button } from 'semantic-ui-react'
import './Events.css'
import hackathonGroup from '../img/hackathon-group.jpg'
import hackathonflyer from '../img/hackathon-flyer.png'

export default class Events extends React.Component {
    render() {
        const hackathon_grid = {
            height: '400px',
            'lineHeight': '100px',
            'margin-bottom': '100px'
          }
        


        return (
            <div>
                
                <Responsive as={Grid} minWidth={992} id='events-grid'>
                    
                    <Grid.Row>
                        <Grid.Column width={8} style={hackathon_grid}>
                           <Image src={hackathonflyer} />
                        </Grid.Column>
                        <Grid.Column id='list-column' width={8}>
                        
                            
                            <List bulleted>
                                {/* {upcoming} */}
                                <h1 id='events-title'>EVENTS</h1>
                            
                                <h2>Don't PANIC will be hosting it's first hackathon from 11/22 to 11/28!</h2>
                                <br />
                               
                                <p>Make sure to read this <a href="https://docs.google.com/document/d/1yM9D1g3uOB3yKUw8EO32F-VPzkn2cD8QqhKYCumr7rk/edit">doc</a> to find out more about the hackathon and it's rules</p>
                                <p>Please fill out this form after reading to apply!</p>
                                <Button><a href="https://docs.google.com/forms/d/1NslTQyc9WtzwUam3PbkOvlStMDJGpXOJtx-Cl3Jheho/edit?usp=sharing">SIGN UP</a></Button>
                            </List>
                            {/* <h2>Past</h2>
                            <List bulleted>
                                {past}
                            </List> */}
                        </Grid.Column>
                    </Grid.Row>
                </Responsive>
                <Responsive maxWidth={991} as='div'>
                    <Image src={hackathonflyer} />
                    <br />
                    <h2>Upcoming</h2>
                    <List bulleted className="mobile-list">
                        {/* {upcoming} */}
                        Check in again next semester for our upcoming events!
                        <br />
                        <br />
                        Have a great summer!
                    </List>
                    {/* <h2>Past</h2>
                    <List bulleted className="mobile-list">
                        {past}
                    </List> */}
                    <br />
                </Responsive>
            </div>
        )
    }

}